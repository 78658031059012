import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';
import Language from '@material-ui/icons/Language';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { Link } from "react-router-dom";

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  navbarLink: {
    color: '#5a6f7b'
  },
  menuList: {
    padding:"5%"
  }
});

const MenuDrawer = (props)=>{
  const classes = useStyles();
  const [state, setState] = React.useState({
    left: false,
  });
  const { data } = props

  const toggleDrawer = (anchor, open) => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };


  return (
    <div className="hamburger">
      <React.Fragment key="left">
        <Button onClick={toggleDrawer("left", true)}><MenuIcon style={{color: "#5a6f7b"}}/></Button>
        <Drawer anchor="left" open={state.left} onClose={toggleDrawer("left", false)}>
          <div
            className={classes.list}
            onClick={toggleDrawer("false", false)}
            onKeyDown={toggleDrawer("false", false)}
            >
            <List className={classes.menuList}>
              <Link to="/"><img height="40px" src={data.logo}/></Link>
              {data.links.map((link, index) => (
                link.path && (link.path.includes('http') || link.path.includes('www'))?
                  (link.separator?
                    "" :
                    <ListItem button key={link.label}>
                      <a key={`key_${index}`} href={link.path}>
                        <ListItemText primary={link.label} className={classes.navbarLink}/>
                      </a>
                    </ListItem>) :
                  (link.separator?
                    "" :
                    <ListItem button key={link.label}>
                      <Link to={link.path}>
                        <ListItemText primary={link.label} className={classes.navbarLink}/>
                      </Link>
                    </ListItem>)
              ))}
            </List>
          </div>
          <div className="language-container">
            <Language className="language" />
            <Select className="switch-language" disableUnderline id="lang-switch" value={props.language} onChange={(e) => props.handleLanguageSwitch(e.target.value)}>
              <MenuItem value="en">English</MenuItem>
              <MenuItem value="es">Español</MenuItem>
              <MenuItem value="pt">Português</MenuItem>
            </Select>
          </div>
        </Drawer>
      </React.Fragment>
    </div>
  );
  }

export default MenuDrawer
