let marketplace  = process.env.REACT_APP_MARKETPLACE_URL || "https://marketplace.dev.finconecta.com"
let institutionPath  = process.env.REACT_APP_INSTITUTION_PATH || ""
let source  = process.env.REACT_APP_SOURCE || ""

export default {
  en: {
    mainTitle: 'DEVELOPER PORTAL',
    logo: '/Logo.png',
    links: [
      {
        path: '/about',
        label: 'About Us',
        separator: false
      },
      {
        path: '',
        label: '',
        separator: true
      },
      {
        path: `${marketplace}${institutionPath}/register?source=${source}`,
        label: 'Sign Up',
        separator: false
      },
      {
        path: `${marketplace}${institutionPath}/login?source=${source}`,
        label: 'Login',
        separator: false
      },
    ]
  },
  es: {
    mainTitle: 'DEVELOPER PORTAL',
    logo: '/Logo.png',
    links: [
      {
        path: '/about',
        label: 'Conócenos',
        separator: false
      },
      {
        path: '',
        label: '',
        separator: true
      },
      {
        path: `${marketplace}${institutionPath}/register?source=${source}`,
        label: 'Registro',
        separator: false
      },
      {
        path: `${marketplace}${institutionPath}/login?source=${source}`,
        label: 'Inicia Sesión',
        separator: false
      },
    ]
  },
  pt: {
    mainTitle: 'Portal de desenvolvedor',
    logo: '/Logo.png',
    links: [
      {
        path: '/about',
        label: 'Conheça-nos',
        separator: false
      },
      {
        path: '',
        label: '',
        separator: true
      },
      {
        path: `${marketplace}${institutionPath}/register?source=${source}`,
        label: 'Registro',
        separator: false
      },
      {
        path: `${marketplace}${institutionPath}/login?source=${source}`,
        label: 'Conecte-se',
        separator: false
      },
    ]
  }
}
