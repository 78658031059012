let marketplace = process.env.REACT_APP_MARKETPLACE_URL || "https://marketplace.dev.finconecta.com"
let institutionPath = process.env.REACT_APP_INSTITUTION_PATH || ""
let institutionName = process.env.REACT_APP_INSTITUTION_NAME || 'FinConecta'
let source = process.env.REACT_APP_SOURCE || ""

export default {
  en: {
    mainTitle: 'Privacy Policy for End Users',
    texts: [
      'Effective Date: January, 2021',
      'This Privacy Policy (the “Policy”) explains how AANDB Tech, Inc. (“Finconecta”, “we”, “our”, and “us”) collects, uses, and shares information about you and the entity you represent in connection with your access to or use of Finconecta’s websites, platforms and/or our other products and services (collectively, the “Finconecta Offerings”), and in connection with any other information we collect when you interact with us.',
      'This Policy applies to all of the information we may collect, generate or otherwise received as a result of your interactions with us through any of the Finconecta Offerings. The “Finconecta Offerings” include any and all products developed, commercialized or otherwise available by Finconecta, with the following list not being exhaustive, 4wrd, Open Finance, OpenBanking Live, OpenBanking Lab, OpenBanking Pro, Finconecta’ s Developer Portal and FinGateway.',
      'This Policy does not apply to any third party applications or software that integrate to any of the Finconecta Offerings (“Third Party Services”), or any other third party products, services or businesses.'
    ],
    steps: [
      {
        title: 'Background',
        texts: [
          'At Finconecta we have developed a series of digital platforms built on the foundation of the most modern and reliable technological infrastructure of APIs and ESB, creating agnostic, modular, flexible, and scalable structures capable of delivering simple, fast, and efficient integration capabilities. Our products seamlessly integrate any IT stack, enabling access to third party solutions provided across the world. We have applied this technology to develop the Finconecta Offerings.',
          'This Policy provides a roadmap of the information Finconecta collects from and about its end users (“End User Information”), from how the End User Information is collected to how it may be used and shared. This Policy describes how we treat your End User Information.',
          'Please note that this Policy only covers the information that we collect, use and share. It does not explain what digital solutions or other third parties may do with any End User Information you or we provide to them. This Policy does not cover any websites, products, or services provided by such digital solutions or other third parties.'
        ]
      },
      {
        title: 'Data Practices',
        texts: [
          'What is this about?',
          'The token is a unique and private credential that allows us to identify your app within our portal in a safe manner.',
          'The secret key is the access to documentation pertaining to our private APIs.',
          `To get access you must complete your registration and send the following documents for the signing of the confidentiality agreement (NDA) with ${institutionName}.`,
          { olist: ['Constitution record', 'Identification of legal representative', 'Proof of address', 'Tax registration'] },
          'Once you deliver the information and document you will have access to our API documentation which you will be able to test in front of our BankBot (banking simulator).'
        ]
      },
      {
        title: 'How We Use Your Information',
        texts: [
          'We use your End User Information mainly to operate, improve, and protect the Finconecta Offerings, and to develop new services. More specifically, we use your End User Information:',
          '- To operate, provide, and maintain our services;',
          '- To improve, enhance, modify, add to, and further develop our services;',
          '- To protect you, digital solutions, our partners, Finconecta, and others from fraud, malicious activity, and other privacy and security-related concerns;',
          '- To develop new services;',
          '- To provide customer support to you or to the digital solutions, including to help respond to your inquiries related to our service or the digital solutions’ applications;',
          '- To investigate any misuse of our service or the digital solutions’ applications, including violations of our Terms of Use, this Policy or other unauthorized access to our services; and',
          '- For other notified purposes with your consent.'
        ]
      },
      {
        title: 'Legal Bases for Processing End User Information (EEA and UK End Users Only)',
        texts: [
          'For individuals in the European Economic Area (“EEA”) or the United Kingdom (“UK”), our legal basis for processing your End User Information will depend on the type of information and the context in which we collected or processed it. Generally, however, we will normally only collect and process End User Information:',
          '1. we need to fulfill our responsibilities and obligations in any contract or agreement with you (for example, to comply with our Terms of Use or Platform as a Service Agreement);',
          '2. to comply with our legal obligations under applicable law;',
          '3. when the processing is necessary for our legitimate interests and not overridden by your data protection interests or fundamental rights and freedoms (for example, to safeguard our services; to communicate with you; or to provide or update our services); and',
          '4. when you have given your consent to do so.',
          '',
          'To the extent we rely on consent to collect and process End User Information, you have the right to withdraw your consent at any time per the instructions provided in this Policy.'
        ]
      },
      {
        title: 'Sharing of End User Information',
        texts: [
          'We share your End User Information for a number of business purposes:',
          '– With the digital solutions you are using through the Finconecta Offerings and as directed by such digital solutions (such as with another third party if directed by you);',
          '– To enforce any contract with you;',
          '– With our data processors and other service providers, partners, or contractors in connection with the services they perform for us or for the digital solutions you use through any of our Finconecta Offerings;',
          '– If we believe in good faith that disclosure is appropriate to comply with applicable law, regulation, or legal process (such as a court order or subpoena);',
          '– In connection with a change in ownership or control of all or a part of our business (such as a merger, acquisition, reorganization, or bankruptcy);',
          '– As we believe reasonably appropriate to protect the rights, privacy, safety, or property of you, the digital solutions, our partners, Finconecta, and others; or',
          '– For any other notified purpose with your consent.',
          '',
          'We may collect, use, and share End User Information in an aggregated, de-identified, or anonymized manner (that does not identify you or the entity you represent personally) for any purpose permitted under applicable law. This includes creating or using aggregated, de-identified, or anonymized data based on the collected information to develop new services and to facilitate research.',
          '',
          'We do not sell or rent personal information that we collect.'
        ]
      },
      {
        title: 'Retention of End User Information',
        texts: [
          'We retain End User Information for no longer than necessary to fulfill the purposes for which it was collected and used, as described in this Policy, unless a longer retention period is required or permitted under applicable law. As permitted under applicable law, even after you stop using the services of a digital solution through any of the Finconecta Offerings or terminate your account with one or more digital solutions, we may still retain your information (for example, if you still have an account with another digital solution). However, your information will only be processed as required by law or in accordance with this Policy.',
          '',
          'Please refer to the “Your Data Protection Rights” section for options that may be available to you, including the right to request deletion of End User Information. You can also contact us about our data retention practices using the contact information below.'
        ]
      },
      {
        title: 'International Data Transfers',
        texts: [
          'We operate internationally, and as a result, will transfer the information we collect about you across international borders, including from the EEA or UK to the United States, for processing and storage. To the extent that the information we collect about you is transferred from the EEA to territories/countries for which the EU Commission has not made a finding that the legal framework in that territory/country provides adequate protection for individuals’ rights and freedoms for their personal data, we will transfer such data consistent with applicable data protection laws, including through the use of the EU Commission-approved standard contractual clauses. You can ask for a copy of these standard contractual clauses by contacting as set out below.'
        ]
      },
      {
        title: 'Data Protection Rights',
        texts: [
          'Under applicable law, and subject to limitations and exceptions provided by law, if you are located in the EEA or UK, and in certain other jurisdictions, you may have certain rights in relation to the End User Information collected about you and how it is used, including the right to:',
          '',
          '– Access End User Information collected about you;',
          '– Request that we rectify or update your End User Information that is inaccurate or incomplete;',
          '– Request, under certain circumstances, that we restrict the processing of or erase your End User Information;',
          '– Object to our processing of your End User Information under certain conditions provided by law;',
          '– Where processing of your End User Information is based on consent, withdraw that consent;',
          '– Request that we provide End User Information collected about you in a structured, commonly used and machine-readable format so that you can transfer it to another company, where technically feasible; and',
          '– File a complaint regarding our data protection practices with a supervisory authority (if you are in the EEA or UK, please refer to the following link for contact details: https://edpb.europa.eu/about-edpb/board/members_en).',
          '',
          'Under the California Consumer Privacy Act (“CCPA”), and subject to certain limitations and exceptions, if you are a California resident, you may have the following rights with respect to End User Information we have collected about you that constitutes personal information under the CCPA:',
          '– To request access to more details about the categories and specific pieces of personal information we may have collected about you in the last 12 months (including personal information disclosed for business purposes);',
          '– To request deletion of your personal information;',
          '– To opt-out of any “sales” of your personal information, if a business is selling your information; and',
          '– To not be discriminated against for exercising these rights.',
          '',
          'To exercise your data protection rights, where applicable, you can contact us as described in the “Contacting Finconecta” section below. You may be required to provide additional information necessary to confirm your identity before we can respond to your request.',
          '',
          'We will provide our response within a reasonable period of time (and within any time period required by applicable law). Please note, however, that certain information may be exempt from such requests, for example if we need to keep the information to comply with our own legal obligations or to establish, exercise, or defend legal claims.'
        ]
      },
      {
        title: 'Changes To This Policy',
        texts: [
          'We may update or change this Policy from time to time. If we make any updates or changes, we will post the new policy on our website at https://openfinance.finconecta.com/privacy-policy/ and update the effective date at the top of this Policy.'
        ]
      },
      {
        title: 'Contacting Finconecta',
        texts: [
          'If you have any questions or complaints about this Policy, or about our privacy practices generally, you can contact us at contact@openfinance.finconecta.com or by mail at:',
          '727 Crandon Boulevard,',
          'Suite #401, Key Biscayne,',
          'FL 33149.'
        ]
      }
    ]
  },
  es: {
    mainTitle: 'Política de privacidad para usuarios finales',
    texts: [
      'Para las personas en el Área Económica Europea ("EEE") o el Reino Unido ("Reino Unido"), nuestra base legal para procesar la información de su usuario final dependerá del tipo de información y el contexto en el que la recopilamos o procesamos.En general, sin embargo, normalmente solo recopilaremos y procesaremos la información del usuario final: ',
      '1.Necesitamos cumplir con nuestras responsabilidades y obligaciones en cualquier contrato o acuerdo con usted (por ejemplo, para cumplir con nuestros Términos de uso o plataforma como Acuerdo de Servicio);',
      '2.cumplir con nuestras obligaciones legales según la ley aplicable; ',
      '3.Cuando el procesamiento es necesario para nuestros intereses legítimos y no anulado por sus intereses de protección de datos o derechos y libertades fundamentales (por ejemplo, para salvaguardar nuestros servicios; comunicarse con usted; o proporcionar o actualizar nuestros servicios);y',
      '4.Cuando haya dado su consentimiento para hacerlo ',
      '',
      'En la medida en que confiamos en el consentimiento para recopilar y procesar la información del usuario final, tiene derecho a retirar su consentimiento en cualquier momento según las instrucciones proporcionadas en esta política.'
    ],
    steps: [
      {
        title: 'Fondo',
        texts: [
          'En Finconecta hemos desarrollado una serie de plataformas digitales construidas sobre la base de la infraestructura tecnológica más moderna y confiable de API y ESB, creando estructuras agnósticas, modulares, flexibles y escalables capaces de ofrecer capacidades de integración simples, rápidas y eficientes.Nuestros productos integran sin problemas cualquier pila de TI, lo que permite el acceso a soluciones de terceros proporcionadas en todo el mundo.Hemos aplicado esta tecnología para desarrollar las ofertas de Finconecta.',
          'Esta política proporciona una hoja de ruta de la información que Finconecta recopila de y sobre sus usuarios finales ("Información del usuario final"), de cómo se recopila la información del usuario final sobre cómo se puede usar y compartir.Esta política describe cómo tratamos la información de su usuario final. ',
          'Tenga en cuenta que esta política solo cubre la información que recopilamos, usamos y compartimos.No explica qué pueden hacer las soluciones digitales u otros terceros con cualquier información del usuario final que usted o que les proporcionamos.Esta política no cubre ningún sitio web, productos o servicios proporcionados por dichas soluciones digitales u otros terceros. '
        ]
      },
      {
        title: 'Prácticas de datos',
        texts: [
          'TBD'
        ]
      },
      {
        title: 'Cómo usamos su información',
        texts: [
          'Utilizamos la información de su usuario final principalmente para operar, mejorar y proteger las ofertas de Finconecta y desarrollar nuevos servicios.Más específicamente, utilizamos la información de su usuario final: ',
          '- operar, proporcionar y mantener nuestros servicios;',
          '- para mejorar, mejorar, modificar, agregar y desarrollar aún más nuestros servicios;',
          '- para protegerlo, soluciones digitales, nuestros socios, Finconecta y otros de fraude, actividad maliciosa y otras preocupaciones relacionadas con la privacidad y la seguridad;',
          '- Desarrollar nuevos servicios;',
          '- para proporcionarle atención al cliente a usted o a las soluciones digitales, incluso para ayudar a responder a sus consultas relacionadas con nuestro servicio o las aplicaciones de soluciones digitales;',
          '- para investigar cualquier uso indebido de nuestro servicio o aplicaciones de soluciones digitales, incluidas las violaciones de nuestros Términos de uso, esta política u otro acceso no autorizado a nuestros servicios;y',
          '- Para otros fines notificados con su consentimiento.'
        ]
      },
      {
        title: 'Bases legales para procesar la información del usuario final (solo usuarios finales de EEA y del Reino Unido)',
        texts: [
          'Para las personas en el área económica europea (" EEE ") o el Reino Unido (" Reino Unido "), nuestra base legal para procesar la información de su usuario final dependerá del tipo de información y el contexto en el que la recopilamos o procesamos.En general, sin embargo, normalmente solo recopilaremos y procesaremos la información del usuario final: ',
          '1.Necesitamos cumplir con nuestras responsabilidades y obligaciones en cualquier contrato o acuerdo con usted (por ejemplo, para cumplir con nuestros Términos de uso o plataforma como Acuerdo de Servicio);',
          '2.cumplir con nuestras obligaciones legales según la ley aplicable; ',
          '3.Cuando el procesamiento es necesario para nuestros intereses legítimos y no anulado por sus intereses de protección de datos o derechos y libertades fundamentales (por ejemplo, para salvaguardar nuestros servicios; comunicarse con usted; o proporcionar o actualizar nuestros servicios);y',
          '4.Cuando haya dado su consentimiento para hacerlo ',
          '',
          'En la medida en que confiamos en el consentimiento para recopilar y procesar la información del usuario final, tiene derecho a retirar su consentimiento en cualquier momento según las instrucciones proporcionadas en esta política.'
        ]
      },
      {
        title: 'Compartir la información del usuario final',
        texts: [
          'Compartimos la información de su usuario final para varios fines comerciales: ',
          ' - con las soluciones digitales que está utilizando a través de las ofertas de FinConecta y, según lo dirigido por tales soluciones digitales (como con otro tercero si usted lo indica);',
          ' - para hacer cumplir cualquier contrato con usted;',
          ' - con nuestros procesadores de datos y otros proveedores de servicios, socios o contratistas en relación con los servicios que realizan para nosotros o para las soluciones digitales que utiliza a través de cualquiera de nuestras ofertas de FinConecta;',
          ' - Si creemos en buena fe que la divulgación es apropiada para cumplir con la ley, la regulación o el proceso legal aplicables (como una orden judicial o citación);',
          ' - En relación con un cambio en la propiedad o control de todo o una parte de nuestro negocio (como una fusión, adquisición, reorganización o bancarrota);',
          ' - Como creemos razonablemente apropiado para proteger los derechos, la privacidad, la seguridad o la propiedad de usted, las soluciones digitales, nuestros socios, Finconecta y otros;o',
          ' - para cualquier otro propósito notificado con su consentimiento',
          '',
          'Podemos recopilar, usar y compartir información del usuario final de una manera agregada, desidentificada o anonimizada (que no identifica a usted o la entidad que representa personalmente) para cualquier propósito permitido según la ley aplicable.Esto incluye la creación o el uso de datos agregados, desidentificados o anonimizados basados en la información recopilada para desarrollar nuevos servicios y para facilitar la investigación.',
          '',
          'No vendemos ni alquilamos información personal que recopilamos.'
        ]
      },
      {
        title: 'Retención de la información del usuario final',
        texts: [
          'Retenemos la información del usuario final por no más de lo necesario para cumplir con los fines para los que se recopiló y usó, como se describe en esta política, a menos que se requiera un período de retención más largo o permiso en virtud de la ley aplicable.Según lo permitido en la ley aplicable, incluso después de dejar de utilizar los servicios de una solución digital a través de cualquiera de las ofertas de Finconecta o rescindir su cuenta con una o más soluciones digitales, aún podemos conservar su información (por ejemplo, si aún tiene una cuentacon otra solución digital).Sin embargo, su información solo se procesará según lo requiera la ley o de acuerdo con esta política.',
          '',
          'Consulte la sección" Sus derechos de protección de datos "para obtener opciones que puedan estar disponibles para usted, incluido el derecho de solicitar la eliminación de la información del usuario final.También puede contactarnos sobre nuestras prácticas de retención de datos utilizando la información de contacto a continuación '
        ]
      },
      {
        title: 'Transferencias de datos internacionales',
        texts: [
          'Operamos internacionalmente y, como resultado, transferiremos la información que recopilamos sobre usted a través de las fronteras internacionales, incluso desde el EEE o el Reino Unido a los Estados Unidos, para su procesamiento y almacenamiento.En la medida en que la información que recopilamos sobre usted se transfiere del EEE a los territorios/países para los cuales la Comisión de la UE no ha hecho un hallazgo de que el marco legal en ese territorio/país brinde una protección adecuada para los derechos y libertades de las personas para sus personajes personales.Datos, transferiremos dichos datos consistentes con las leyes de protección de datos aplicables, incluso mediante el uso de las cláusulas contractuales estándar aprobadas por la Comisión de la UE.Puede solicitar una copia de estas cláusulas contractuales estándar contactando como se establece a continuación.'
        ]
      },
      {
        title: 'Derechos de protección de datos',
        texts: [
          'Según la ley aplicable, y sujeto a limitaciones y excepciones proporcionadas por la ley, si se encuentra en el EEE o el Reino Unido, y en ciertas otras jurisdicciones, puede tener ciertos derechos en relación con la información del usuario final recopilada sobre usted y cómo estáusado, incluido el derecho a: ',
          '',
          ' - Acceda a la información del usuario final recopilada sobre usted;',
          ' - Solicite que rectifique o actualice la información de su usuario final que sea inexacta o incompleta;',
          ' - Solicite, bajo ciertas circunstancias, que restringimos el procesamiento o bordeamos la información de su usuario final',
          ' - Objeto a nuestro procesamiento de la información de su usuario final bajo ciertas condiciones proporcionadas por la ley;',
          ' - donde el procesamiento de la información de su usuario final se basa en el consentimiento, retire ese consentimiento;',
          '-Solicite que proporcionemos información del usuario final recopilada sobre usted en un formato estructurado, comúnmente utilizado y legible por máquina para que pueda transferirla a otra empresa, donde técnicamente es factible;y',
          '-Presente una queja sobre nuestras prácticas de protección de datos con una autoridad de supervisión (si está en el EEE o el Reino Unido, consulte el siguiente enlace para obtener datos de contacto: https://edpb.europa.eu/about-edpb/board/miembros_en). ',
          '',
          'Según la Ley de Privacidad del Consumidor de California ("CCPA"), y sujeto a ciertas limitaciones y excepciones, si usted es residente de California, puede tener los siguientes derechos con respecto a la información del usuario final que hemos recopilado sobre usted que constituye información personal bajoEl CCPA: ',
          ' - Para solicitar acceso a más detalles sobre las categorías y las piezas específicas de información personal que podríamos haber recopilado sobre usted en los últimos 12 meses (incluida la información personal divulgada para fines comerciales);',
          ' - Para solicitar la eliminación de su información personal;',
          '-para optar por no participar en cualquier "venta" de su información personal, si una empresa está vendiendo su información;y',
          ' - no ser discriminado por ejercer estos derechos',
          '',
          'Para ejercer sus derechos de protección de datos, cuando corresponda, puede contactarnos como se describe en la sección "Contactar a Finconecta" a continuación.Es posible que deba proporcionar información adicional necesaria para confirmar su identidad antes de que podamos responder a su solicitud ',
          '',
          'Proporcionaremos nuestra respuesta dentro de un período de tiempo razonable (y dentro de cualquier período de tiempo requerido por la ley aplicable).Sin embargo, tenga en cuenta que cierta información puede estar exenta de tales solicitudes, por ejemplo, si necesitamos mantener la información para cumplir con nuestras propias obligaciones legales o establecer, hacer ejercicio o defender reclamos legales '
        ]
      },
      {
        title: 'Cambios a esta política',
        texts: [
          'Podemos actualizar o cambiar esta política de vez en cuando.Si hacemos alguna actualización o cambio, publicaremos la nueva política en nuestro sitio web en https://openfinance.finconecta.com/privacy-policy/ y actualizaremos la fecha de vigencia en la parte superior de esta política.'
        ]
      },
      {
        title: 'Contactar a Finconecta',
        texts: [
          'Si tiene alguna pregunta o queja sobre esta política, o sobre nuestras prácticas de privacidad en general, puede contactarnos a contact@openfinance.finconecta.com o por correo a:',
          '727 Crandon Boulevard,',
          'Suite #401, Key Biscayne,',
          'FL 33149.'
        ]
      }
    ]
  },
  pt: {
    mainTitle: 'Política de privacidade para usuários finais',
    texts: [
      'Data de validade: janeiro de 2021 ',
      'Esta política de privacidade (a "política") explica como a Aandb Tech, Inc. ("Faroncta", "nós", "Ours" e "Us") coleta, usa e compartilham informações sobre você e a entidade que representa em conexão com a conexão com Seu acesso ou uso de sites, plataformas e/ou outros produtos e serviços da FinConeta (coletivamente, o "Farconeta oferece") e em relação a qualquer outra informação que coletamos ao interagir conosco ".',
      'Esta política se aplica a todas as informações que podemos coletar, gerar ou receber como resultado de suas interações conosco através de qualquer uma das ofertas da FarConeta. As "ofertas FarConnect" incluem cada um dos produtos desenvolvidos, comercializados ou disponíveis de outra forma de Faroncta, e a lista a seguir não é exaustiva, 4WRD, Open Finance, OpenBanking Live, OpenBanking Lab, OpenBanking Pro, FinConeta Developer Portal e Pingwayway.',
      'Esta política não se aplica a nenhum aplicativo ou software de terceira parte integrado a qualquer uma das ofertas da FinConeta ("Serviços de terceiros"), ou qualquer outro produto, serviços ou empresas de terceira parte.'
    ],
    steps: [
      {
        title: 'Fundo',
        texts: [
          'Na FinConecta, desenvolvemos uma série de plataformas digitais construídas com base na base da infraestrutura tecnológica mais moderna e confiável de APIs e ESB, criando estruturas agnósticas, modulares, flexíveis e escaláveis capazes de fornecer recursos de integração simples, rápidos e eficientes.Nossos produtos integram perfeitamente qualquer pilha de TI, permitindo o acesso a soluções de terceiros fornecidos em todo o mundo.Aplicamos essa tecnologia para desenvolver as ofertas da FinConecta. ',
          'Esta política fornece um roteiro das informações que a FinCoCTA coleta de e sobre seus usuários finais (“Informações do usuário final”), de como as informações do usuário final são coletadas para como elas podem ser usadas e compartilhadas.Esta política descreve como tratamos as informações do seu usuário final. ',
          'Observe que esta política cobre apenas as informações que coletamos, usamos e compartilhamos.Ele não explica o que as soluções digitais ou outros terceiros podem fazer com as informações do usuário final que você ou nós fornecemos a elas.Esta política não cobre sites, produtos ou serviços fornecidos por essas soluções digitais ou outros terceiros.'
        ]
      },
      {
        title: 'Data Practices',
        texts: [
          'TBD'
        ]
      },
      {
        title: 'How We Use Your Information',
        texts: [
          'Usamos as informações do usuário final principalmente para operar, melhorar e proteger as ofertas da FinCoCecta e desenvolver novos serviços.Mais especificamente, usamos as informações do usuário final: ',
          '- Operar, fornecer e manter nossos serviços;',
          '- melhorar, aprimorar, modificar, adicionar e desenvolver nossos serviços;',
          '- Para proteger você, soluções digitais, nossos parceiros, FinConecta e outros de fraude, atividade maliciosa e outras preocupações relacionadas à privacidade e segurança;',
          '- desenvolver novos serviços;',
          '- fornecer suporte ao cliente a você ou às soluções digitais, inclusive para ajudar a responder às suas perguntas relacionadas ao nosso serviço ou aos aplicativos de soluções digitais;',
          '- Investigar qualquer uso indevido de nosso serviço ou os aplicativos da Digital Solutions, incluindo violações de nossos Termos de Uso, esta Política ou outro acesso não autorizado aos nossos serviços;e',
          '- Para outros propósitos notificados com seu consentimento.'
        ]
      },
      {
        title: 'Bases legais para processamento de informações do usuário final (apenas usuários finais do EEE e Reino Unido)',
        texts: [
          'Para indivíduos na área econômica européia (“EEA”) ou no Reino Unido (“Reino Unido”), nossa base legal para processar as informações do usuário final dependerá do tipo de informação e do contexto em que a coletamos ou processamos.Geralmente, no entanto, normalmente coletaremos e processaremos apenas as informações do usuário final: ',
          '1.Precisamos cumprir nossas responsabilidades e obrigações em qualquer contrato ou contrato com você (por exemplo, para cumprir nossos Termos de Uso ou Plataforma como Contrato de Serviço); ',
          '2.cumprir nossas obrigações legais sob a lei aplicável; ',
          '3.Quando o processamento é necessário para nossos interesses legítimos e não substituído por seus interesses de proteção de dados ou direitos e liberdades fundamentais (por exemplo, para proteger nossos serviços; para se comunicar com você; ou fornecer ou atualizar nossos serviços);e',
          '4.Quando você deu seu consentimento para fazê -lo. ',
          '',
          'Na medida em que confiamos no consentimento para coletar e processar as informações do usuário final, você tem o direito de retirar seu consentimento a qualquer momento, de acordo com as instruções fornecidas nesta política.'
        ]
      },
      {
        title: 'Compartilhamento de informações do usuário final',
        texts: [
          'Compartilhamos suas informações do usuário final para vários fins comerciais:',
          ' - com as soluções digitais que você está usando através das ofertas da FinConecta e conforme indicado por essas soluções digitais (como com outro terceiro, se dirigido por você);',
          ' - Para fazer cumprir qualquer contrato com você;',
          ' - com nossos processadores de dados e outros provedores de serviços, parceiros ou contratados em conexão com os serviços que executam para nós ou para as soluções digitais que você usa por meio de qualquer uma de nossas ofertas da FinConecta;',
          ' - Se acreditarmos em boa fé que a divulgação é apropriada para cumprir a lei, regulamentação ou processo legal aplicável (como uma ordem judicial ou intimação);',
          ' - em conexão com uma mudança de propriedade ou controle de todos ou parte de nossos negócios (como fusão, aquisição, reorganização ou falência);',
          ' - Como acreditamos razoavelmente apropriado para proteger os direitos, privacidade, segurança ou propriedade de você, as soluções digitais, nossos parceiros, FinConecta e outros;ou',
          ' - para qualquer outro propósito notificado com seu consentimento.',
          '',
          'Podemos coletar, usar e compartilhar as informações do usuário final de maneira agregada, desidentificada ou anonimizada (que não identifica você ou a entidade que você representa pessoalmente) para qualquer finalidade permitida pela lei aplicável.Isso inclui a criação ou o uso de dados agregados, não identificados ou anonimizados com base nas informações coletadas para desenvolver novos serviços e facilitar a pesquisa. ',
          '',
          "Não vendemos ou alugamos informações pessoais que coletamos."
        ]
      },
      {
        title: 'Retenção de informações do usuário final',
        texts: [
          'Mantemos as informações do usuário final por não mais do que o necessário para cumprir os propósitos para os quais foram coletados e usados, conforme descrito nesta política, a menos que um período de retenção mais longo seja necessário ou permitido pela lei aplicável.Conforme permitido pela lei aplicável, mesmo depois de parar de usar os serviços de uma solução digital através de qualquer uma das ofertas da FinCoCecta ou encerrar sua conta com uma ou mais soluções digitais, ainda podemos manter suas informações (por exemplo, se você ainda tiver uma contacom outra solução digital).No entanto, suas informações serão processadas apenas conforme exigido por lei ou de acordo com esta política. ',
          '',
          'Consulte a seção “Seus direitos de proteção de dados” para obter opções que podem estar disponíveis para você, incluindo o direito de solicitar a exclusão das informações do usuário final.Você também pode entrar em contato conosco sobre nossas práticas de retenção de dados usando as informações de contato abaixo. '
        ]
      },
      {
        title: 'Transferências de dados internacionais',
        texts: [
          'Operamos internacionalmente e, como resultado, transferiremos as informações que coletamos sobre você através de fronteiras internacionais, inclusive do EEE ou Reino Unido para os Estados Unidos, para processamento e armazenamento.Na medida em que as informações que coletamos sobre você são transferidas do EEE para territórios/países para os quais a Comissão da UE não fez uma constatação de que a estrutura legal nesse território/país fornece proteção adequada aos direitos e liberdades dos indivíduos para seu pessoalDados, transferiremos esses dados consistentes com as leis de proteção de dados aplicáveis, inclusive através do uso das cláusulas contratuais padrão aprovadas pela Comissão da UE.Você pode solicitar uma cópia dessas cláusulas contratuais padrão, contatando conforme estabelecido abaixo. '
        ]
      },
      {
        title: 'Direitos de proteção de dados',
        texts: [
          'De acordo com a lei aplicável e sujeita a limitações e exceções fornecidas por lei, se você estiver localizado no EEE ou no Reino Unido e em outras jurisdições, você pode ter certos direitos em relação às informações do usuário final coletadas sobre você e como elas são usadas, incluindo o direito de: ',
          '',
          ' - Acesse as informações do usuário final coletadas sobre você;',
          ' - solicite que corrigimos ou atualizemos as informações do usuário final que são imprecisas ou incompletas;',
          ' - Solicitar, sob certas circunstâncias, que restrinamos o processamento ou apagar suas informações do usuário final;',
          ' - Objeta -se ao nosso processamento de suas informações do usuário final sob certas condições fornecidas por lei;',
          ' - Onde o processamento das informações do usuário final é baseado no consentimento, retire esse consentimento;',
          '-solicite que forneçamos informações finais do usuário coletadas sobre você em um formato estruturado, comumente usado e legível por máquina, para que você possa transferi-lo para outra empresa, quando tecnicamente viável;e',
          '-Faça uma queixa sobre nossas práticas de proteção de dados com uma autoridade de supervisão (se você estiver no EEE ou no Reino Unido, consulte o seguinte link para obter detalhes de contato: https://edpb.europa.eu/about-edpb/board/membros_en). ',
          '',
          'De acordo com a Lei de Privacidade do Consumidor da Califórnia (“CCPA”) e sujeita a certas limitações e exceções, se você é um residente da Califórnia, pode ter os seguintes direitos em relação às informações finais do usuário que coletamos sobre você que constitui informações pessoais sobo CCPA: ',
          ' - Para solicitar o acesso a mais detalhes sobre as categorias e informações específicas de informações pessoais, podemos ter coletado sobre você nos últimos 12 meses (incluindo informações pessoais divulgadas para fins comerciais);',
          ' - para solicitar a exclusão de suas informações pessoais;',
          ' - Para optar por não obter qualquer "venda" de suas informações pessoais, se uma empresa estiver vendendo suas informações;e',
          ' - não ser discriminado por exercer esses direitos.',
          '',
          'Para exercer seus direitos de proteção de dados, quando aplicável, você pode entrar em contato conosco, conforme descrito na seção “Entrando em contato com FinConecta” abaixo.Você pode ser obrigado a fornecer informações adicionais necessárias para confirmar sua identidade antes de podermos responder à sua solicitação. ',
          '',
          'Forneceremos nossa resposta dentro de um período razoável de tempo (e em qualquer período exigido pela lei aplicável).Observe, no entanto, que certas informações podem estar isentas de tais solicitações, por exemplo, se precisarmos manter as informações para cumprir nossas próprias obrigações legais ou estabelecer, exercer ou defender reivindicações legais. '
        ]
      },
      {
        title: 'Mudanças nesta política',
        texts: [
          'Podemos atualizar ou alterar esta política de tempos em tempos.Se fizermos alguma atualização ou alteração, publicaremos a nova política em nosso site em https://openfinance.finconecta.com/privacy-policy/ e atualize a data efetiva no topo desta política.'
        ]
      },
      {
        title: 'Entrando em contato com FinConecta',
        texts: [
          'Se você tiver alguma dúvida ou reclamação sobre esta política, ou sobre nossas práticas de privacidade em geral, entre em contato conosco em contact@openfinance.finconecta.com ou por e -mail em:',
          '727 Crandon Boulevard,',
          'Suite #401, Key Biscayne,',
          'FL 33149.'
        ]
      }
    ]
  },
}
