let marketplace  = process.env.REACT_APP_MARKETPLACE_URL || "https://marketplace.dev.finconecta.com"
let institutionPath  = process.env.REACT_APP_INSTITUTION_PATH || ""
let institutionName = process.env.REACT_APP_INSTITUTION_NAME || 'FinConecta'
let source  = process.env.REACT_APP_SOURCE || ""


export default {
  en: {
    mainTitle: 'START BUILDING',
    texts: [
      `It is very simple to start the process with ${institutionName} for Developers. Our APIs have been developed to generate multiple reasons for use and create innovative business opportunities. We provide clear and simple documentation to facilitate exploration and simplify its development.`,
      "Do you have any questions? We are here to answer any doubts that may arise. Please consult with our chatbot,it will be our pleasure to assist you. "
    ],
    steps: [
      {
        title: '1. Register',
        texts:[
          'Are you a new member of the portal? Registering is very simple. You just need to fill in our registration form. We will send you an email with a link to activate your account.',
          {button: 'Register', external: `${marketplace}${institutionPath}/register?source=${source}`},
          "Are you a member of our ecosystem? Begin your session and start editing or creating your apps.",
          {button: "Sign In", external: `${marketplace}${institutionPath}/login?source=${source}`}
        ]
      },
      {
        title: '2. Token Access/Secret key',
        texts:[
          'What is this about?',
          'The token is a unique and private credential that allows us to identify your app within our portal in a safe manner.',
          'The secret key is the access to documentation pertaining to our private APIs.',
          `To get access you must complete your registration and send the following documents for the signing of the confidentiality agreement (NDA) with ${institutionName}.`,
          {olist: ['Constitution record','Identification of legal representative','Proof of address','Tax registration']},
          'Once you deliver the information and document you will have access to our API documentation which you will be able to test in front of our BankBot (banking simulator).'
        ]
      },
      {
        title: '3. Subscribe to our Sandbox',
        texts:[
          'Now comes the fun part! ',
          'Our API documentation will teach you how to format HTTPS calls. Complete the required information and you will be able to start the process of building your application in a controlled environment.'
        ]
      },
      {
        title: '4. Make your first API Call',
        texts:[
          'Congratulations!',
          'When you finish the testing stage in the sandbox, our team will give you all the support you need to take the application into production'
        ]
      }
    ]
  },
  es: {
    mainTitle: 'EMPIEZA A CONSTRUIR',
    texts: [
      `Empezar a construir con ${institutionName} para Desarrolladores es muy sencillo. Nuestras APIs han sido desarrolladas para generar múltiples  casos de uso y crear innovadoras oportunidades de negocio. Proveemos documentación clara y sencilla para facilitar la exploración y simplificar el desarrollo.`,
      "¿Tienes preguntas?  Estamos listos para responder a todas tus dudas. Consulta con nuestro chatbot será un gusto poder atenderte."
    ],
    steps: [
      {
        title: '1. Registro',
        texts:[
          '¿Eres nuevo en el portal? Registrarte es muy sencillo. Solo tienes que llenar nuestro formato de registro. Te enviaremos un correo con un enlace para activar tu cuenta',
          {button: 'Registro', external: `${marketplace}${institutionPath}/register?source=${source}`},
          "¿Ya eres miembro de nuestro ecosistema? Inicia tu sesión para editar o crear tus apps.",
          {button: "Inicia tu sesión", external: `${marketplace}${institutionPath}/login?source=${source}`}
        ]
      },
      {
        title: '2. Acceso a Token/secret key',
        texts:[
          '¿De qué se trata?',
          'El token es una credencial única y privada que nos permitirá identificar tu aplicación dentro del portal de una manera segura.',
          'El secret key es el acceso a la documentación de nuestras APIs privadas.',
          `Para obtener el acceso debes completar tu registro y enviar los siguientes documentos para la firma del acuerdo de confidencialidad (NDA) con ${institutionName}`,
          {olist: ['Acta constitutiva','Identificación responsable legal','Comprobante de domicilio','Registro fiscal']},
          'Una vez que completes la información tendrás acceso a la documentación de nuestras APIs que podrás testear frente a nuestro simulador bancario BankBot'
        ]
      },
      {
        title: '3. Suscríbete a nuestro Sandbox',
        texts:[
          'Ahora empieza la parte divertida.',
          'Nuestra documentación de APIs te enseñara como se pueden formatear tus llamadas HTTPS. Completa la información solicitada  podrás empezar a construir  tu aplicación a partir de data en un ambiente controlado. '
        ]
      },
      {
        title: '4. Haz tu primer API Call',
        texts:[
          'Felicitaciones! Al finalizar la etapa de testeo en el sandbox, nuestro equipo te dará todo el soporte que necesitas para llevar la aplicación a producción.'
        ]
      }
    ]
  },
  pt: {
    mainTitle: 'Comece a construir',
    texts: [
      `Comece a construir ${institutionName} Para os desenvolvedores, é muito simples.Nossas APIs foram desenvolvidas para gerar vários casos de uso e criar oportunidades inovadoras de negócios.Fornecemos documentação clara e simples para facilitar a exploração e simplificar o desenvolvimento.`,
      "Você tem perguntas? Estamos prontos para responder a todas as suas dúvidas. Consulte nosso chatbot será um prazer poder ajudá -lo."
    ],
    steps: [
      {
        title: '1. Registro',
        texts:[
          'Você é novo no portal?O registro é muito simples.Você só precisa preencher nosso formato de registro. Enviaremos um e -mail com um link para ativar sua conta',
          {button: 'Registro', external: `${marketplace}${institutionPath}/register?source=${source}`},
          "Você já é membro do nosso ecossistema?Comece sua sessão para editar ou criar seus aplicativos.",
          {button: "Comece sua sessão", external: `${marketplace}${institutionPath}/login?source=${source}`}
        ]
      },
      {
        title: '2. Acesso a Token/secret key',
        texts:[
          'Do que se trata?',
          'Token é uma credencial única e privada que nos permitirá identificar seu aplicativo no portal de uma maneira segura.',
          'A chave secreta é o acesso à documentação de nossas APIs privadas.',
          `Para obter acesso, você deve concluir seu registro e enviar os seguintes documentos para a assinatura do contrato de confidencialidade (NDA) com ${institutionName}`,
          {olist: ['Ato constitutivo ',' identificação legal responsável ',' prova de endereço ',' registro fiscal']},
          'Depois de concluir as informações, você terá acesso à documentação de nossas APIs que você pode testar em frente ao nosso simulador de banco de banco'
        ]
      },
      {
        title: '3. Inscreva -se em nossa caixa de areia',
        texts:[
          'Agora a parte engraçada começa.',
          'Nossa documentação de APIs ensinará como suas chamadas podem ser formatadas. Preencha as informações solicitadas, você pode começar a criar seu aplicativo a partir de dados em um ambiente controlado. '
        ]
      },
      {
        title: '4. Faça sua primeira chamada API',
        texts:[
          'Parabéns! No final da fase de teste na caixa de areia, nossa equipe fornecerá todo o suporte necessário para levar o aplicativo à produção.'
        ]
      }
    ]
  }
}
