import React from 'react';
import ReactCountryFlag from "react-country-flag"
let source  = process.env.REACT_APP_SOURCE || ""

const countries = require('country-list');


const APICategory = (props)=>{
  const { data, cardFront, clickedAPI } = props
  const countryCodes = []
  let marketplace  = process.env.REACT_APP_MARKETPLACE_URL || "https://marketplace.dev.finconecta.com"
  let institutionPath  = process.env.REACT_APP_INSTITUTION_PATH || ""
  // console.log(data);
  if('apiCountries' in data && data.apiCountries.length) data.apiCountries.map((c,i) =>
      countryCodes.push(countries.getCode(c))
    )


  return(
    <div className="api-container" style={clickedAPI? {transform: 'rotateY(180deg)'} : {}}>
      <div  className="card-front">
        <img
          className="api-image"
          src={'apiImage' in data && data.apiImage? `${marketplace}/api/logo/${data.apiImage}` : '/image-api-placeholder.png'}
          alt={'apiImage' in data? data.apiImage : 'api-image'}/>
        <div className="api-front-data">
          <div className="api-title">
            <img
              className="api-icon"
              src={'apiIcon' in data && data.apiIcon? `${marketplace}/api/logo/${data.apiIcon}` : '/icons/icon-placeholder.png'}
              alt={'apiIcon' in data? data.apiIcon : ''}/>
            <p>{'apiName' in data? data.apiName : ''}</p>
          </div>
          <p>{'apiDescription' in data? data.apiDescription : ''}</p>
          <div className="tags">
            {'apiStage' in data && data.apiStage? <p className="stage">{data.apiStage}</p> : ''}
            {'apiVisibility' in data && data.apiVisibility? <p className="visibility">{data.apiVisibility}</p> : ''}
          </div>
        </div>
      </div>
      <div className="card-back">
        <p className="main-title">{'apiName' in data? data.apiName: ''}</p>
        <div className="api-back-data">
          {/*
          <div className="top-container">
            <p className="title" style={{marginBottom: '0'}}>Information:</p>
            <p className="something-missing">Information Missing</p>
          </div>*/}
          {/* <div className="flags-container">
            <p className="title">Countries:</p>
            {countryCodes.length? countryCodes.map((c, i) =>
                <ReactCountryFlag
                  countryCode={c}
                  svg
                  key={i}
                  style={{
                    borderRadius: '100%',
                    objectFit: 'cover',
                    width: '40px',
                    height: '40px',
                    marginRight: '0.7em',
                    padding: '0.1em'
                }}/>
            ) : <p className="something-missing">Countries Missing</p>}
          </div>*/}
          <div className="bottom-container">
            <p className="title">Certifications:</p>
            <div className="certifications">
              {'apiCertifications' in data && data.apiCertifications.length? data.apiCertifications.map((c,i) =>
                <p key={`key_${i}}`} className="certification">{c}</p>
              ): <p className="something-missing">Certifications Missing</p>}
            </div>
          </div>
          <div className="register">
            <a href={`${marketplace}${institutionPath}/register?source=${source}`} target="_blank" rel="noopener noreferrer"><p className="title">Register to Review</p></a>
          </div>
        </div>
     </div>
    </div>
  )
}

export default APICategory
