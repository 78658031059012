import React from 'react';
import axios from 'axios'
import { BrowserRouter as Router, Route } from "react-router-dom";

// Pages
import Home from './Home.js'
import About from './About.js'
import Catalog from './Catalog.js'
import Build from './Build.js'
import PrivacyPolicy from './PrivacyPolicy.js'

// Components
import Navbar from './components/Navbar.js'
import CompressedNavbar from './components/CompressedNavbar.js'

// Contents
import homeData from './content/home.js'
import aboutData from './content/about.js'
import catalogData from './content/catalog.js'
import navbarData from './content/navbar.js'
import buildData from './content/build.js'
import PrivacyPolicyData from './content/privacy-policy.js'

class App extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      language: 'en',
      apiInformation: []
    };
    this.handleLanguageSwitch = this.handleLanguageSwitch.bind(this)
  }

  componentDidMount(){
    console.log(process.env);
    if(localStorage.getItem('4lng')){
      this.setState({language: localStorage.getItem('4lng')},() => {
        this.getAPIsInformation('', this.state.language)
      })
    }else{
      this.getAPIsInformation('', this.state.language)
    }
  }

  async getAPIsInformation(id, lang=''){
    let marketplace  = process.env.REACT_APP_MARKETPLACE_URL || "https://marketplace.dev.finconecta.com"
    let paramsString = ''
    if(lang && lang !== 'en'){
      paramsString = id? `summary?entity=${id}&lang=${lang}` :`summary?lang=${lang}`
    }else{
      paramsString = id? `summary?entity=${id}` : `summary`
    }
    await axios.get(`${marketplace}/api/apis/${paramsString}`).then(res => {
      this.setState({apiInformation: res.data})
    });
  }

  handleLanguageSwitch(lang){
    this.setState({language: lang})
    localStorage.setItem('4lng', lang);
    window.location.reload()
    this.getAPIsInformation('', lang)
  }

  render(){
    const { language, apiInformation } = this.state

    return(
      <div>
        <Router>
          <Navbar data={navbarData[`${language}`]} language={language} handleLanguageSwitch={this.handleLanguageSwitch}/>
          <CompressedNavbar data={navbarData[`${language}`]} language={language} handleLanguageSwitch={this.handleLanguageSwitch}/>
          <Route
            exact
            path='/'
            render={(props) => <Home {...props} data={homeData[`${language}`]}/>}
          />
          <Route
            exact
            path='/about'
            render={(props) => <About {...props} data={aboutData[`${language}`]}/>}
          />
          <Route
            exact
            path='/catalog'
            render={(props) => <Catalog {...props}
                                  data={apiInformation}
                                  language={language}
            />}
          />
          <Route
            exact
            path='/build'
            render={(props) => <Build {...props} data={buildData[`${language}`]}/>}
          />
          <Route
            exact
            path='/privacy-policy'
            render={(props) => <PrivacyPolicy {...props} data={PrivacyPolicyData[`${language}`]}/>}
          />
        </Router>
      </div>
    )
  }
}

export default App
