import React from 'react';
import MenuDrawer from './MenuDrawer.js';
import SearchIcon from '@material-ui/icons/Search';
import { Link } from "react-router-dom";

const CompressedNavbar = (props)=>{
  const { data, language, handleLanguageSwitch } = props
    return(
      <div className="compressed-navbar-container">
        <MenuDrawer
          data={data}
          language={language}
          handleLanguageSwitch={handleLanguageSwitch}
          />
        <div className="compressed-navbar-logo-container">
          <Link to="/"><img height="40px" src={data.logo} alt="logo"/></Link>
        </div>
        <div className="search">
          <SearchIcon/>
        </div>
      </div>
    )
  }

export default CompressedNavbar
