let institutionName = process.env.REACT_APP_INSTITUTION_NAME || 'FinConecta'


export default {
  en: {
    welcome: {
      image: '/welcome-pic.png',
      title: `Welcome to ${institutionName} for Developers`,
      texts: [],
      subtitle: 'Let innovation reign free via our platform for developers '
    },
    cards: [
      {
        title: 'Browse APIs',
        text: 'Explore our catalogue',
        link: '/catalog',
        icon: '/icons/ico-orgchart.svg'
      },
      {
        title: 'Start Building',
        text: "Let's go!",
        link: '/build',
        icon: '/icons/ico-dev.svg'
      },
    ],
    slides: [
      {
        title: '',
        image: 'https://cdn.pixabay.com/photo/2017/08/01/23/23/people-2568603_1280.jpg',
        texts: [
          'Open Banking uses open APIs which allow external developers to create applications and services around the financial institution',
          'By working together and leveraging APIs, banks, fintechs and other service providers can leverage the synergies of an integrated ecosystem with the ultimate goal of providing a better user experience'
        ],
        buttonText: 'Browse APIs',
        buttonLink: '/catalog'
      },
      {
        title: '25 most innovative & transformative bankers',
        image: 'https://cdn.pixabay.com/photo/2018/01/15/07/51/woman-3083382_1280.jpg',
        texts: [
          'We are excited to introduce our 2019 Top 25 Most Innovative Bankers in Latin America & the Caribbean.',
          'This year’s list of the bankers – and banks! - showcases a variety of ways that institutions are leveraging technology and innovation to transform for the digital age.'
        ],
        buttonText: '',
        buttonLink: ''
      },
    ],
    bottomBox:{
        title: 'Partner with Us',
        texts: [
          'Connect with new clients.',
          `Whether you are adding information of your account or creating a new payment solution, we make it easy for you to connect your innovative products with ${institutionName} customers`,
          'It is easier than you think. Register today!'
        ],
        image: 'https://cdn.pixabay.com/photo/2015/02/02/11/09/office-620822_1280.jpg',
        buttonLink: '/build',
        buttonText: 'Start building'
      }
  },
  es: {
    welcome: {
      image: '/welcome-pic.png',
      title: `Bienvenido a ${institutionName} para Desarrolladores`,
      texts: [],
      subtitle: 'Libera la Innovación a través de nuestra Plataforma para Desarrolladores'
    },
    cards: [
      {
        title: 'Ver APIs',
        text: 'Explora nuestro catálogo',
        link: '/catalog',
        icon: '/icons/ico-orgchart.svg'
      },
      {
        title: 'Empieza a Construir',
        text: 'Vamos!',
        link: '/build',
        icon: '/icons/ico-dev.svg'
      },
    ],
    slides: [
      {
        title: '',
        image: 'https://cdn.pixabay.com/photo/2017/08/01/23/23/people-2568603_1280.jpg',
        texts: [
          'Open Banking es el uso de APIs abiertas que permiten a desarrolladores externos construir aplicaciones y servicios alrededor de la Institución Financiera.',
          'Al trabajar juntos y aprovechar las APIs, los bancos, las fintech y otros proveedores de servicios pueden apalancar las sinergias de un ecosistema integrado con el objetivo final de proporcionar una mejor experiencia de usuario'],
        buttonText: 'Ver APIs',
        buttonLink: '/catalog'
      },
      {
        title: 'Los 25 banqueros más innovadores y transformadores',
        image: 'https://cdn.pixabay.com/photo/2018/01/15/07/51/woman-3083382_1280.jpg',
        texts: [
          'Estamos muy entusiasmados de presentarte los 25 banqueros más Innovadores de Latino América y el Caribe del 2019.  La lista de banqueros y bancos de  este año  destaca la variedad de formas que instituciones están apalancando la tecnología y la innovación para transformarse para esta era digital. '
        ],
        buttonText: '',
        buttonLink: ''
      },
    ],
    bottomBox:{
        title: 'Asóciate con Nosotros',
        texts: [
          `Conéctate con nuevos clientes. Ya sea que estés agregando información de la cuenta o creando una nueva solución de pago, facilitamos la conexión de tus productos innovadores con los clientes de ${institutionName}.`,
          'Es más fácil de lo que crees. Regístrate hoy!'
        ],
        image: 'https://cdn.pixabay.com/photo/2015/02/02/11/09/office-620822_1280.jpg',
        buttonLink: '/build',
        buttonText: 'Empieza a construir'
      }
  },
  pt: {
    welcome: {
      image: '/welcome-pic.png',
      title: `bem-vindo a ${institutionName} Para desenvolvedores`,
      texts: [],
      subtitle: 'Libere a inovação através de nossa plataforma de desenvolvedor'
    },
    cards: [
      {
        title: 'Ver Apis',
        text: 'Explore nosso catálogo',
        link: '/catalog',
        icon: '/icons/ico-orgchart.svg'
      },
      {
        title: 'Comece a construir',
        text: 'vamos!',
        link: '/build',
        icon: '/icons/ico-dev.svg'
      },
    ],
    slides: [
      {
        title: '',
        image: 'https://cdn.pixabay.com/photo/2017/08/01/23/23/people-2568603_1280.jpg',
        texts: [
          'Open Banking é o uso de APIs abertas que permitem aos desenvolvedores externos criar aplicativos e serviços em torno da instituição financeira.',
          'Ao trabalhar juntos e aproveitar as APIs, os bancos, a fintech e outros provedores de serviços podem aproveitar as sinergias de um ecossistema integrado com o objetivo final de proporcionar uma melhor experiência do usuário'],
        buttonText: 'Ver APIs',
        buttonLink: '/catalog'
      },
      {
        title: 'Os 25 banqueiros mais inovadores e transformadores',
        image: 'https://cdn.pixabay.com/photo/2018/01/15/07/51/woman-3083382_1280.jpg',
        texts: [
          'Estamos muito animados em apresentar a você os 25 banqueiros mais inovadores da América Latina e o Caribe de 2019. A lista de banqueiros e bancos deste ano destaca a variedade de formas que as instituições estão aproveitando a tecnologia e a inovação para transformar para esta era digital. '
        ],
        buttonText: '',
        buttonLink: ''
      },
    ],
    bottomBox:{
        title: 'Como conosco',
        texts: [
          `Conecte -se com novos clientes.Esteja você adicionando informações de conta ou criando uma nova solução de pagamento, facilitamos a conexão de seus produtos inovadores com clientes de ${institutionName}.`,
          'É mais fácil do que você pensa.Inscreva-se hoje!'
        ],
        image: 'https://cdn.pixabay.com/photo/2015/02/02/11/09/office-620822_1280.jpg',
        buttonLink: '/build',
        buttonText: 'Comece a construir'
      }
  },
}
