import React from 'react';
import Language from '@material-ui/icons/Language';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { Link } from "react-router-dom";

const Navbar = (props)=>{
  const { data, language } = props

    return(
      <div className="navbar-container">
        <div className="navbar-logo-container">
          <Link to="/"><img height="40px" alt="bank-logo" src={data.logo}/></Link>
          <p>{data.mainTitle}</p>
        </div>
        <div className="nav-links">
          {data.links.map((l,i) =>
            l.path && (l.path.includes('http') || l.path.includes('www'))?
            (l.separator? <p key={`key_${i}`} className="separator"/> : <a key={`key_${i}`} href={l.path}><p>{l.label}</p></a>) :
            (l.separator? <p key={`key_${i}`} className="separator"/> : <Link key={`key_${i}`} to={l.path}><p>{l.label}</p></Link>)
          )}
          <div className="language-container">
            <Language className="language" />
            <Select className="switch-language" disableUnderline id="lang-switch" value={language} onChange={(e) => props.handleLanguageSwitch(e.target.value)}>
              <MenuItem value="en">English</MenuItem>
              <MenuItem value="es">Español</MenuItem>
              <MenuItem value="pt">Portugués</MenuItem>
            </Select>
          </div>
        </div>
      </div>
    )
  }

export default Navbar
